import React from "react";

export function useClickOutside(callback) {
  const node = React.useRef(null);

  const handleClick = React.useCallback(
    (event) => {
      if (callback && node.current && !node.current.contains(event.target)) {
        callback(event, node.current);
      }
    },
    [callback]
  );

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [handleClick]);

  return node;
}
