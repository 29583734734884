import COINS, { NATIVE } from "./COINS";
import COINS_PRECISION from "./COINS_PRECISION";
import ENV from "./ENV";
import SERVICES from "./SERVICES";
import NETWORKS from "./NETWORKS";

export default {
  COINS,
  NATIVE,
  COINS_PRECISION,

  ENV,
  SERVICES,
  NETWORKS,
};
