import fetchStatusHandler from "./fetchStatusHandler";

import config from "../config";

export default function requestFunds({ address, currencyTicker, amount }) {
  if (!config.adminApi) return Promise.reject("Admin API URL not configured");

  return fetch(
    `${config.adminApi}/${currencyTicker.toLowerCase()}/admin/addbalance`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ address, amount }),
    }
  )
    .then(fetchStatusHandler)
    .then((response) => response.json());
}
