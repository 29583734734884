export default {
  web3: "web3",
  bitcoin: "bitcoin",
  coininfo: "coininfo",
  p2pLib: "p2pLib",
  peerId: "peerId",
  IpfsRoom: "IpfsRoom",
  storage: "storage",
  sessionStorage: "sessionStorage",
  swapsExplorer: "swapsExplorer",
};
