import { STR } from "../actions/swap";

export const initial = {
  ids: [],
};

export function reducer(state = initial, action) {
  switch (action.type) {
    case STR.CREATE_SWAP_COMPLETE:
      return {
        ...state,
        ids: Array.from(new Set([...state.ids, action.swap.id])),
      };
    default:
      return state;
  }
}
