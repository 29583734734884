import { call, put, take, takeEvery } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";

import { push } from "connected-react-router";
import { toast } from "react-toastify";

import SwapApp from "../../vendor/swap.core/swap.app";

import SwapCache from "../../util/swapCache";

import * as swapActions from "../actions/swap";

function* createOrder({ data, callback }) {
  const SwapOrders = SwapApp.shared().services.orders;
  const order = yield call(SwapOrders.create.bind(SwapOrders), data);
  yield put(swapActions.updateOrders());
  if (callback) callback(order);
  toast.success("Order created!");
}

function* copyOrder({ orderId }) {
  const order = SwapApp.shared().services.orders.getByKey(orderId);
  yield put(
    swapActions.createOrder({
      buyCurrency: order.buyCurrency,
      buyAmount: order.buyAmount,
      sellCurrency: order.sellCurrency,
      sellAmount: order.sellAmount,
    })
  );
}

function* removeMyOrder({ orderId }) {
  const SwapOrders = SwapApp.shared().services.orders;
  yield call(SwapOrders.remove.bind(SwapOrders), orderId);
  yield put(swapActions.updateOrders());
}

function* initiateSwap({ orderId }) {
  const order = SwapApp.shared().services.orders.getByKey(orderId);
  yield put(swapActions.sendOrderRequest(order));
}

function* sendOrderRequest({ order }) {
  const accepted = yield call(
    () => new Promise((resolve) => order.sendRequest(resolve))
  );

  if (accepted) {
    yield put(swapActions.createSwap(order));
  } else {
    toast.warn("Swap request declined");
  }
}

function* receiveOrderRequest({ request }) {
  const order = SwapApp.shared().services.orders.getByKey(request.orderId);
  const accept = !order.isProcessing && window.confirm("Accept order request?");
  if (accept) {
    yield call(order.acceptRequest.bind(order), request.participant.peer);
    yield put(swapActions.createSwap(order));
  } else {
    yield call(order.declineRequest.bind(order), request.participant.peer);
  }
}

function* createSwap({ order }) {
  const swap = SwapCache.createSwap(order);
  yield put(swapActions.createSwapComplete(order, swap));
}

function* createSwapComplete({ order, swap }) {
  yield put(push(`/swaps/${swap.id}`));

  const updateChannel = yield call(swapProgressChannel, swap);

  if (swap.flow && typeof swap.flow.sign === "function") swap.flow.sign();

  try {
    while (true) {
      yield take(updateChannel);
      yield put(swapActions.updateOrders());
    }
  } finally {
    yield put(swapActions.swapFinished(swap));

    // Delete the order
    if (order.isMy) yield put(swapActions.removeMyOrder(order.id));

    toast.success("Swap finished!");
  }
}

function swapProgressChannel(swap) {
  return eventChannel((emitter) => {
    swap.on("progress update", emitter);
    swap.once("swap finished", () => emitter(END));
    return () => swap.off("progress update", emitter);
  });
}

export default function* swap() {
  yield takeEvery(swapActions.STR.CREATE_ORDER, createOrder);
  yield takeEvery(swapActions.STR.COPY_ORDER, copyOrder);
  yield takeEvery(swapActions.STR.REMOVE_MY_ORDER, removeMyOrder);
  yield takeEvery(swapActions.STR.INITIATE_SWAP, initiateSwap);
  yield takeEvery(swapActions.STR.SEND_ORDER_REQUEST, sendOrderRequest);
  yield takeEvery(swapActions.STR.RECEIVE_ORDER_REQUEST, receiveOrderRequest);
  yield takeEvery(swapActions.STR.CREATE_SWAP, createSwap);
  yield takeEvery(swapActions.STR.CREATE_SWAP_COMPLETE, createSwapComplete);
}
