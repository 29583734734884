import { STR } from "../actions/ui";

export const initial = { sideBarOpen: false };

export function reducer(state = initial, action) {
  switch (action.type) {
    case STR.TOGGLE_SIDEBAR:
      return {
        ...state,
        sideBarOpen: !state.sideBarOpen,
      };
    case STR.OPEN_SIDEBAR:
      return {
        ...state,
        sideBarOpen: true,
      };
    case STR.CLOSE_SIDEBAR:
      return {
        ...state,
        sideBarOpen: false,
      };
    default:
      return state;
  }
}
