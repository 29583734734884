export const STR = {
  UPDATE_BALANCE: "WALLET_UPDATE_BALANCE",
  UPDATE_BTC_BALANCE: "WALLET_UPDATE_BTC_BALANCE",
  UPDATE_ETH_BALANCE: "WALLET_UPDATE_ETH_BALANCE",
  UPDATE_BALANCE_SUCCESS: "WALLET_UPDATE_BALANCE_SUCCESS",
  REQUEST_FUNDS: "WALLET_REQUEST_FUNDS",
  REQUEST_FUNDS_SUCCESS: "WALLET_REQUEST_FUNDS_SUCCESS",
};

export const updateBalance = (currencyTicker) => ({
  type: STR.UPDATE_BALANCE,
  currencyTicker: currencyTicker.toUpperCase(),
});

export const updateBTCBalance = () => ({
  type: STR.UPDATE_BTC_BALANCE,
});

export const updateETHBalance = () => ({
  type: STR.UPDATE_ETH_BALANCE,
});

export const updateBalanceSuccess = (currencyTicker, balance) => ({
  type: STR.UPDATE_BALANCE_SUCCESS,
  currencyTicker: currencyTicker.toUpperCase(),
  balance,
});

export const requestFunds = (currencyTicker, amount) => ({
  type: STR.REQUEST_FUNDS,
  currencyTicker: currencyTicker.toUpperCase(),
  amount,
});

export const requestFundsSuccess = (currencyTicker) => ({
  type: STR.REQUEST_FUNDS_SUCCESS,
  currencyTicker: currencyTicker.toUpperCase(),
});
