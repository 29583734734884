import React, { useState } from "react";
import classnames from "classnames";
import {
  ChevronUp as IconChevronUp,
  ChevronDown as IconChevronDown,
  Check as IconCheck,
} from "react-feather";

import { Small } from "./Typography";
import CurrencyLogo from "./CurrencyLogo";

import { useClickOutside } from "../util/clickOutside";

import styles from "./CurrencySelector.module.css";

const CurrencySelector = ({ active = {}, options = [], onChange }) => {
  const [open, setOpen] = useState(false);

  const handleClickOutside = React.useCallback(() => {
    if (open) setOpen(false);
  }, [open]);

  const node = useClickOutside(handleClickOutside);

  const handleClickOption = (option) => {
    onChange(option);
    setOpen(false);
  };

  return (
    <span ref={node} className={styles.dropdown}>
      <button onClick={() => setOpen(!open)}>
        <CurrencyOption {...active} />
        {open ? (
          <IconChevronUp stroke="#D1CFD3" />
        ) : (
          <IconChevronDown stroke="#D1CFD3" />
        )}
      </button>
      <span className={classnames(styles.options, { [styles.open]: open })}>
        {options.map((option) => (
          <button
            key={`option-${option.name}`}
            className={classnames(styles.option, {
              [styles.active]: active.ticker === option.ticker,
            })}
            onClick={() => handleClickOption(option)}
          >
            <CurrencyOption {...option} />
            <IconCheck stroke="#D1CFD3" />
          </button>
        ))}
      </span>
    </span>
  );
};

const CurrencyOption = ({ name, ticker }) => (
  <span className={styles.currencyOption}>
    <CurrencyLogo className={styles.currencyLogo} name={ticker} />
    <span className={styles.currencyName}>
      <h3>{name}</h3>
      <Small>{ticker}</Small>
    </span>
  </span>
);

export { CurrencySelector };
