import { connect } from "react-redux";

import Footer from "../components/Footer";

const mapStateToProps = (state) => ({
  p2pOnline: state.p2p.online,
  p2pPeerCount: state.p2p.onlineUsers,
  appVersion: process.env.REACT_APP_VERSION,
  network: process.env.REACT_APP_CONFIG,
});

export default connect(mapStateToProps, null)(Footer);
