export const STR = {
  HIDE_CURRENCY: "USER_HIDE_CURRENCY",
  UNHIDE_CURRENCY: "USER_UNHIDE_CURRENCY",
};

export const hideCurrency = (currency) => ({
  type: STR.HIDE_CURRENCY,
  currency,
});

export const unhideCurrency = (currency) => ({
  type: STR.UNHIDE_CURRENCY,
  currency,
});
