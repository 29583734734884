export const STR = {
  TOGGLE_SIDEBAR: "UI_TOGGLE_SIDEBAR",
  OPEN_SIDEBAR: "UI_OPEN_SIDEBAR",
  CLOSE_SIDEBAR: "UI_CLOSE_SIDEBAR",
};

export const toggleSideBar = () => ({
  type: STR.TOGGLE_SIDEBAR,
});

export const openSideBar = () => ({
  type: STR.OPEN_SIDEBAR,
});

export const closeSideBar = () => ({
  type: STR.CLOSE_SIDEBAR,
});
