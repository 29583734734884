import React from "react";
import classnames from "classnames";

import styles from "./Typography.module.css";

const Title = (props) => <h1 {...props}>{props.children}</h1>;
const Small = (props) => (
  <small {...props} className={classnames(styles.small, props.className)} />
);
const Smaller = (props) => (
  <small {...props} className={classnames(styles.smaller, props.className)} />
);

const Large = (props) => (
  <span {...props} className={classnames(styles.large, props.className)} />
);

const Larger = (props) => (
  <span {...props} className={classnames(styles.larger, props.className)} />
);

const Colored = ({ children, className, color }) => (
  <span
    className={classnames(styles.colored, className)}
    style={{
      color: color,
      stroke: color,
    }}
  >
    {children}
  </span>
);

export { Title, Small, Smaller, Large, Larger, Colored };
