import { STR } from "../actions/wallet";

export const initial = {
  balance: {
    BTC: 0,
    ETH: 0,
  },
  balanceUpdated: {
    BTC: null,
    ETH: null,
  },
};

export function reducer(state = initial, action) {
  switch (action.type) {
    case STR.UPDATE_BALANCE_SUCCESS:
      return {
        ...state,
        balance: { ...state.balance, [action.currencyTicker]: action.balance },
        balanceUpdated: {
          ...state.balanceUpdated,
          [action.currencyTicker]: Date.now(),
        },
      };
    default:
      return state;
  }
}
