import React from "react";
import classnames from "classnames";

import styles from "./Boxes.module.css";

const Rounded = ({
  active = false,
  children,
  topLeft = false,
  topRight = false,
  bottomRight = false,
  bottomLeft = false,
  className,
}) => (
  <div
    className={classnames(
      styles.box,
      styles.rounded,
      {
        [styles.topLeft]: topLeft,
        [styles.topRight]: topRight,
        [styles.bottomLeft]: bottomLeft,
        [styles.bottomRight]: bottomRight,
        [styles.active]: active,
      },
      className
    )}
  >
    {children}
  </div>
);

const Muted = ({ children }) => (
  <div className={classnames(styles.box, styles.muted)}>{children}</div>
);

export { Rounded, Muted };
