import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Activity as IconActivity,
  Clock as IconClock,
  Settings as IconSettings,
  Shield as IconShield,
} from "react-feather";

import { Main, Row } from "./components/Grid";

import Header from "./containers/Header";
import SideBar from "./containers/SideBar";
import Footer from "./containers/Footer";

import TradePage from "./pages/TradePage";
import HistoryPage from "./pages/HistoryPage";
import SettingsPage from "./pages/SettingsPage";
import SwapPage from "./pages/SwapPage";
import NotFound from "./pages/NotFound";

import AdminPage from "./pages/AdminPage";

import "./style.css";

const pages = [
  {
    name: "Trade",
    path: "/",
    component: TradePage,
    icon: IconActivity,
    exact: true,
  },
  {
    name: "History",
    path: "/history",
    component: HistoryPage,
    icon: IconClock,
    exact: false,
  },
  {
    name: "Settings",
    path: "/settings",
    component: SettingsPage,
    icon: IconSettings,
    exact: false,
  },
];

if (process.env.NODE_ENV === "development") {
  pages.push({
    name: "Admin",
    path: "/admin",
    component: AdminPage,
    icon: IconShield,
    exact: false,
  });
}

const routes = [
  ...pages,
  {
    name: "Swaps",
    path: "/swaps/:swapId",
    component: SwapPage,
    exact: true,
  },
];

const App = () => (
  <React.Fragment>
    <Header />
    <Row style={{ overflow: "auto" }}>
      <SideBar pages={pages} />
      <Main style={{ height: "100%" }}>
        <Switch>
          {routes.map((route) => (
            <Route key={route.name} {...route} />
          ))}
          <Route path="*" component={NotFound} />
        </Switch>
      </Main>
    </Row>
    <Footer />
  </React.Fragment>
);

export default App;
