import { connect } from "react-redux";

import * as uiActions from "../redux/actions/ui";

import SideBar from "../components/SideBar";

const mapStateToProps = (state) => ({
  isOpen: state.ui.sideBarOpen,
});

const mapDispatchToProps = (dispatch) => ({
  close: (...args) => dispatch(uiActions.closeSideBar(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
