import { connect } from "react-redux";

import * as swapActions from "../redux/actions/swap";
import * as walletActions from "../redux/actions/wallet";

import AdminView from "../components/AdminView";

const mapStateToProps = (state) => ({
  swapAppReady: state.swap.appReady,
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: (...args) => dispatch(swapActions.createOrder(...args)),
  requestFunds: (...args) => dispatch(walletActions.requestFunds(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
