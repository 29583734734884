import React from "react";

import { Col } from "./Grid";
import { Rounded } from "./Boxes";
import { Button } from "./Input";
import CurrencyLogo from "./CurrencyLogo";
import { Title, Small } from "./Typography";

import styles from "./SettingsView.module.css";

const SettingsView = (props) => {
  return (
    <Col>
      <Title>Currencies</Title>

      {props.visibleCurrencies && props.visibleCurrencies.length ? (
        <React.Fragment>
          <Small>Visible</Small>
          <Rounded className={styles.box}>
            {props.visibleCurrencies.map((c) => (
              <ToggleCurrency
                key={`hide-${c.name}`}
                actionName="hide"
                currency={c}
                toggle={props.hideCurrency}
              />
            ))}
          </Rounded>
        </React.Fragment>
      ) : null}

      {props.hiddenCurrencies && props.hiddenCurrencies.length ? (
        <React.Fragment>
          <Small style={{ marginTop: "0.5rem" }}>Hidden</Small>
          <Rounded className={styles.box}>
            {props.hiddenCurrencies.map((c) => (
              <ToggleCurrency
                key={`unhide-${c.name}`}
                actionName="unhide"
                currency={c}
                toggle={props.unhideCurrency}
              />
            ))}
          </Rounded>
        </React.Fragment>
      ) : null}
    </Col>
  );
};

const ToggleCurrency = ({ currency, actionName, toggle }) => (
  <span className={styles.toggleCurrency} key={currency.ticker}>
    <span>
      <CurrencyLogo className={styles.currencyLogo} name={currency.ticker} />
      {currency.name}
    </span>
    <Button small onClick={() => toggle(currency)}>
      {actionName}
    </Button>
  </span>
);

export default SettingsView;
