import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as p2pReducer, initial as p2pInitial } from "./p2p";
import { reducer as uiReducer, initial as uiInitial } from "./ui";
import { reducer as userReducer, initial as userInitial } from "./user";
import { reducer as swapReducer, initial as swapInitial } from "./swap";
import { reducer as walletReducer, initial as walletInitial } from "./wallet";
import {
  reducer as swapHistoryReducer,
  initial as swapHistoryInitial,
} from "./swapHistory";

export const initialState = {
  p2p: p2pInitial,
  ui: uiInitial,
  user: userInitial,
  swap: swapInitial,
  wallet: walletInitial,
  swapHistory: swapHistoryInitial,
};

export function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    p2p: p2pReducer,
    ui: uiReducer,
    user: userReducer,
    swap: swapReducer,
    wallet: walletReducer,
    swapHistory: swapHistoryReducer,
  });
}
