import React from "react";

import Swap from "./Swap";

const SwapView = ({ swapId, getSwapById }) => {
  const [swap, setSwap] = React.useState(null);

  React.useEffect(() => {
    const swap = getSwapById(swapId);
    if (swap) setSwap(swap);
  }, [getSwapById, swapId]);

  return <Swap swap={swap} />;
};

export default SwapView;
