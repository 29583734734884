import React from "react";
import classnames from "classnames";

import SelectCurrencies from "../containers/SelectCurrencies";

import { Grid, Row, Col } from "./Grid";
import Trade from "./Trade";
import OrderBook from "./OrderBook";

import styles from "./TradeView.module.css";

const TradeView = (props) => {
  return (
    <Grid>
      <Row colSpan={2} className={classnames(styles.currencies)}>
        <SelectCurrencies {...props} />
      </Row>
      <Col rowSpan={2} className={classnames(styles.tradeBox)}>
        <Trade {...props} />
      </Col>
      <Col colSpan={2} className={classnames(styles.orderbook)}>
        <OrderBook {...props} />
      </Col>
    </Grid>
  );
};

export default TradeView;
