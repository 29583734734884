import devnet from "./devnet";
import testnet from "./testnet";
import mainnet from "./mainnet";

const configs = {
  devnet,
  testnet,
  mainnet,
};

const defaultConfig = {
  adminApi: process.env.REACT_APP_ADMIN_API,
  api: {
    btc: "",
    eth: "",
  },
  feeRates: {
    btc: "",
    eth: "",
  },
  explorer: {
    btc: "",
    eth: "",
  },
  p2p: {
    starServer: "",
    roomName: "",
  },
  swapContract: {
    eth: "",
  },
};

export default { ...defaultConfig, ...configs[process.env.REACT_APP_CONFIG] };
