import React from "react";
import { connect } from "react-redux";
import { Code as IconCode } from "react-feather";

import * as swapActions from "../redux/actions/swap";

import * as userSelectors from "../redux/selectors/user";
import * as walletSelectors from "../redux/selectors/wallet";

import { Col } from "../components/Grid";
import { Title } from "../components/Typography";
import CurrencyBox from "../components/CurrencyBox";

import explorerURL from "../util/explorerURL";
import { useWindowSize } from "../util/breakpoints";

import styles from "./SelectCurrencies.module.css";

const mapStateToProps = (state) => ({
  activeBuyAddress: walletSelectors.activeBuyAddress(state),
  activeSellAddress: walletSelectors.activeSellAddress(state),
  visibleCurrencies: userSelectors.visibleCurrencies(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrencyBuy: (...args) => dispatch(swapActions.setCurrencyBuy(...args)),
  setCurrencySell: (...args) => dispatch(swapActions.setCurrencySell(...args)),
  switchCurrencies: (...args) =>
    dispatch(swapActions.switchCurrencies(...args)),
});

const SelectCurrencies = (props) => {
  const { isSmall } = useWindowSize();
  return (
    <React.Fragment>
      <Col>
        <Title>Sell</Title>
        <CurrencyBox
          asset={props.activeSell}
          options={props.visibleCurrencies}
          onChange={props.setCurrencySell}
          balance={props.balances[props.activeSell.ticker]}
          walletURL={explorerURL(
            props.activeSell.ticker,
            props.activeSellAddress
          )}
        />
      </Col>

      {isSmall ? null : (
        <button className={styles.switch} onClick={props.switchCurrencies}>
          <IconCode stroke="#D1CFD3" />
        </button>
      )}

      <Col>
        <Title>Buy</Title>
        <CurrencyBox
          asset={props.activeBuy}
          options={props.visibleCurrencies}
          onChange={props.setCurrencyBuy}
          balance={props.balances[props.activeBuy.ticker]}
          walletURL={explorerURL(
            props.activeBuy.ticker,
            props.activeBuyAddress
          )}
        />
      </Col>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCurrencies);
