import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import * as swapSelectors from "../redux/selectors/swap";

import SwapView from "../components/SwapView";

const mapStateToProps = (state) => ({
  getSwapById: (...args) => swapSelectors.getSwapById(...args),
});

const SwapPage = (props) => {
  const { swapId } = useParams();
  return <SwapView {...props} swapId={swapId} />;
};

export default connect(mapStateToProps, null)(SwapPage);
