import React from "react";
import classnames from "classnames";

import { Small } from "./Typography";
import { Rounded } from "./Boxes";

import { useWindowSize } from "../util/breakpoints";

import styles from "./OrderBook.module.css";
import boxStyles from "./Boxes.module.css";
import buttonStyles from "./Button.module.css";

const OrderBook = ({ orders = [], orderActions }) => {
  const { isLarge } = useWindowSize();

  const headers = isLarge
    ? ["AMOUNT", "PRICE", "TOTAL", "ACTIONS"]
    : ["AMOUNT", "TOTAL", "ACTIONS"];

  return (
    <React.Fragment>
      <div className={boxStyles.margin}>
        <Small>Order Book</Small>
      </div>
      <Rounded bottomRight className={styles.orderBook}>
        <table>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={`header-${header}`}>
                  <Small>{header}</Small>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orders.map((order, i) => (
              <tr key={`order-${i}`}>
                <td>
                  <Small style={{ marginRight: "0.3rem" }}>Buying</Small>
                  {order.buyAmount.toNumber()}
                  <Small style={{ marginLeft: "0.3rem" }}>
                    {order.buyCurrency}
                  </Small>
                </td>
                {isLarge ? (
                  <td>
                    <Small style={{ marginRight: "0.3rem" }}>at</Small>
                    {order.price.toFixed(8)}
                    <Small style={{ marginLeft: "0.3rem" }}>
                      {order.sellCurrency} / {order.buyCurrency}
                    </Small>
                  </td>
                ) : null}
                <td>
                  <Small style={{ marginRight: "0.3rem" }}>for</Small>
                  {order.sellAmount.toNumber()}
                  <Small style={{ marginLeft: "0.3rem" }}>
                    {order.sellCurrency}
                  </Small>
                </td>
                <td>
                  <OrderActions order={order} actions={orderActions} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Rounded>
    </React.Fragment>
  );
};

const OrderActions = ({ order, actions }) => {
  const removeOrderButton = (
    <button
      className={classnames(buttonStyles.button, buttonStyles.danger)}
      onClick={() => {
        const ok = window.confirm("Remove order?");
        if (ok) actions.removeMyOrder(order.id);
      }}
    >
      Remove
    </button>
  );

  const copyOrderButton = (
    <button
      className={classnames(buttonStyles.button, buttonStyles.primary)}
      onClick={() => actions.copyOrder(order.id)}
    >
      Copy order
    </button>
  );

  const initiateSwapButton = (
    <button
      className={classnames(buttonStyles.button, buttonStyles.success)}
      onClick={() => actions.initiateSwap(order.id)}
    >
      Start swap
    </button>
  );

  return (
    <React.Fragment>
      {order.isMy ? removeOrderButton : null}
      {!order.isMy && !order.isProcessing ? initiateSwapButton : null}
      {process.env.NODE_ENV === "development" ? copyOrderButton : null}
    </React.Fragment>
  );
};

export default OrderBook;
