export const STR = {
  READY: "P2P_READY",
  STOP: "P2P_STOP",
  USER_JOINED: "P2P_USER_JOINED",
  USER_LEFT: "P2P_USER_LEFT",
};

export const setReady = (peerInfo) => ({
  type: STR.READY,
  peerInfo,
});

export const setStop = () => ({
  type: STR.STOP,
});

export const userJoined = () => ({
  type: STR.USER_JOINED,
});

export const userLeft = () => ({
  type: STR.USER_LEFT,
});
