import React from "react";
import classnames from "classnames";

import styles from "./Input.module.css";

const InputLabel = ({ label, ...rest }) => (
  <label className={styles.inputLabel} {...rest}>
    <span>{label}</span>
  </label>
);

const TextInput = ({ label, id, ...rest }) => (
  <span className={styles.inputWrapper}>
    <input type="text" className={styles.textInput} id={id} {...rest} />
    <InputLabel label={label} htmlFor={id} />
  </span>
);

const NumberInput = ({ label, id, ...rest }) => (
  <span className={styles.inputWrapper}>
    <input type="number" className={styles.textInput} id={id} {...rest} />
    <InputLabel label={label} htmlFor={id} />
  </span>
);

const Button = ({ fullWidth, small, className, ...rest }) => (
  <button
    className={classnames(styles.button, className, {
      [styles.small]: small,
      [styles.fullWidth]: fullWidth,
    })}
    {...rest}
  />
);

export { NumberInput, TextInput, Button };
