import React from "react";

import { Col } from "./Grid";
import { Title, Large, Small } from "./Typography";
import { NumberInput, Button } from "./Input";

const Trade = ({
  activeSell,
  activeBuy,
  balances,
  swapAppReady,
  createOrder,
  marketRate = "",
}) => {
  const [sellAmount, setSellAmount] = React.useState("");
  const [exchangeRate, setExchangeRate] = React.useState(marketRate);

  const sellBalance = balances[activeSell.ticker];
  const buyAmount = sellAmount * exchangeRate;

  const formDisabled = !swapAppReady || buyAmount <= 0;

  React.useEffect(() => {
    setSellAmount("");
    setExchangeRate(marketRate);
  }, [activeBuy, activeSell, marketRate]);

  const handleClearForm = React.useCallback(() => {
    setSellAmount("");
    setExchangeRate(marketRate);
  }, [marketRate]);

  const handleSetSellAmount = (event) => {
    const amount = event.target.value;
    if (0 <= amount && amount <= sellBalance) setSellAmount(amount);
  };

  const handleSetExchangeRate = (event) => {
    const rate = event.target.value;
    if (0 <= rate) setExchangeRate(rate);
  };

  const handleCreateOrder = (event) => {
    event.preventDefault();

    if (formDisabled) return;

    const orderData = {
      buyCurrency: activeBuy.ticker,
      sellCurrency: activeSell.ticker,
      buyAmount,
      sellAmount,
    };

    createOrder(orderData, handleClearForm);
  };

  return (
    <form onSubmit={handleCreateOrder}>
      <Title>Trade</Title>

      <Small>You send:</Small>
      <NumberInput
        value={sellAmount}
        label={activeSell.ticker}
        onChange={handleSetSellAmount}
        disabled={sellBalance <= 0}
      />

      <Small>Exchange rate:</Small>
      <NumberInput value={exchangeRate} onChange={handleSetExchangeRate} />

      <ReceivedAmount
        amount={sellAmount * exchangeRate}
        currency={activeBuy.ticker}
      />

      <Button
        fullWidth
        disabled={formDisabled}
        type="submit"
        style={{ marginTop: "2rem" }}
      >
        <Large>Create Order</Large>
      </Button>
    </form>
  );
};

const ReceivedAmount = ({ amount, currency }) => (
  <Col style={{ marginLeft: "1rem", marginTop: "1rem", flexGrow: 0 }}>
    <Small>Estimated received amount</Small>
    <Large style={{ fontSize: "1.1em" }}>
      = {amount} {currency}
    </Large>
  </Col>
);

export default Trade;
