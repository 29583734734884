/*
 * Sort by object property, works on strings and numbers
 */
export function objectSort(property, ascending = true) {
  return function (a, b) {
    if (!(property in a) || !(property in b))
      throw new Error(`Can not sort by non-existing property "${property}"`);

    const aVal = Number(a[property]) || String(a[property]);
    const bVal = Number(b[property]) || String(b[property]);
    const aType = typeof aVal;
    const bType = typeof bVal;

    if (aType === bType) {
      if (aType === "number") {
        return ascending
          ? numberCompare(aVal, bVal)
          : numberCompare(bVal, aVal);
      }
      if (aType === "string") {
        return ascending
          ? stringCompare(aVal, bVal)
          : stringCompare(bVal, aVal);
      }
      throw new Error("Can only sort strings and numbers");
    }

    throw new Error("Unable to sort unmatching types", aType, bType);
  };
}

function stringCompare(a, b) {
  return a.localeCompare(b);
}

function numberCompare(a, b) {
  return a - b;
}
