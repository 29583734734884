import React from "react";

import { Smaller, Large } from "./Typography";
import { CurrencySelector } from "./CurrencySelector";
import { Rounded, Muted } from "./Boxes";

const CurrencyBox = ({ asset, options, onChange, balance, walletURL }) => (
  <React.Fragment>
    <Rounded topRight>
      <CurrencySelector active={asset} options={options} onChange={onChange} />
    </Rounded>
    <Muted>
      <Smaller>CURRENT BALANCE</Smaller>
      <Large>
        {walletURL && walletURL !== "" ? (
          <a href={walletURL} target="_blank" rel="noopener noreferrer">
            {Number(balance)} {asset.ticker}
          </a>
        ) : (
          <React.Fragment>
            {Number(balance)} {asset.ticker}
          </React.Fragment>
        )}
      </Large>
    </Muted>
  </React.Fragment>
);

export default CurrencyBox;
