import { connect } from "react-redux";
import { push } from "connected-react-router";

import HistoryView from "../components/HistoryView";

import { swapHistory } from "../redux/selectors/swapHistory";

const mapStateToProps = (state) => ({
  swaps: swapHistory(state),
});

const mapDispatchToProps = (dispatch) => ({
  swapActions: {
    viewSwap: (swapId) => dispatch(push(`/swaps/${swapId}`)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryView);
