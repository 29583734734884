import React from "react";

import { Row } from "./Grid";

import styles from "./Footer.module.css";

const Footer = (props) => {
  return (
    <Row className={styles.footer}>
      <div className={styles.item}>version: {props.appVersion}</div>
      <div className={styles.item}>network: {props.network}</div>
      <div className={styles.item}>
        <span>
          p2p:{" "}
          <span style={{ color: props.p2pOnline ? "green" : "red" }}>
            {props.p2pOnline ? "online" : "offline"}
          </span>
        </span>
      </div>
      <div className={styles.item}>peers: {props.p2pPeerCount}</div>
    </Row>
  );
};

export default Footer;
