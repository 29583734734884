import { createSelector } from "reselect";

import SwapApp from "../../vendor/swap.core/swap.app";

export const activeBuy = (state) => state.swap.buy;
export const activeSell = (state) => state.swap.sell;
export const appReady = (state) => state.swap.appReady;

export const addresses = createSelector(appReady, (appReady) => {
  if (!appReady) return null;
  const data = SwapApp.shared().services.auth.getPublicData();
  return Object.keys(data).reduce((acc, currencyTicker) => {
    if (["btc", "eth"].indexOf(currencyTicker) !== -1) {
      acc[currencyTicker.toUpperCase()] = data[currencyTicker].address;
    }
    return acc;
  }, {});
});

const getAddress = (addresses, currency) => {
  if (addresses) return addresses[currency.ticker];
  return null;
};

export const activeBuyAddress = createSelector(
  addresses,
  activeBuy,
  getAddress
);

export const activeSellAddress = createSelector(
  addresses,
  activeSell,
  getAddress
);
