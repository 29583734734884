import React from "react";
import {
  Check as IconCheck,
  Circle as IconCircle,
  CheckCircle as IconCheckCircle,
} from "react-feather";
import classnames from "classnames";
import { format } from "date-fns";

import { Col } from "./Grid";
import { Title, Small } from "./Typography";
import { Rounded } from "./Boxes";
import { Grid } from "./Grid";

import { useWindowSize } from "../util/breakpoints";
import { txUrl } from "../util/explorerURL";

import styles from "./Swap.module.css";

const Swap = (props) => {
  const { isSmall } = useWindowSize();
  return (
    <Col>
      <Title>Swap</Title>
      {!props.swap ? (
        "Swap not available"
      ) : (
        <React.Fragment>
          <SwapProgress {...props} />
          <Grid className={styles.grid}>
            <SwapInfo topLeft className={styles.info} {...props} />
            <SwapSteps
              topRight={!isSmall}
              className={styles.steps}
              {...props}
            />
            <SwapLinks
              bottomRight
              bottomLeft
              className={styles.links}
              {...props}
            />
          </Grid>
        </React.Fragment>
      )}
    </Col>
  );
};

const SwapProgress = ({ swap }) => {
  const { flow } = swap;

  if (!flow) return null;

  const finished = flow.isFinished;
  const progress = (flow.step + 1) / flow.totalSteps;

  return (
    <div className={styles.progressWrapper}>
      {finished ? (
        <React.Fragment>
          <IconCheck />
          <span style={{ marginLeft: "0.4rem" }}>Swap finished</span>
        </React.Fragment>
      ) : (
        <span className={styles.progressBar}>
          <span style={{ width: `${progress * 100}%` }} />
        </span>
      )}
    </div>
  );
};

const SwapInfo = ({ swap, ...rest }) => {
  const { flow } = swap;

  if (!flow) return null;

  return (
    <Rounded {...rest}>
      <dl>
        <dt>
          <Small>Timestamp</Small>
        </dt>
        <dd>
          {format(new Date(swap.createUnixTimeStamp * 1000), "PP kk:mm OOO")}
        </dd>
        <dt>
          <Small>Sell</Small>
        </dt>
        <dd>
          {swap.sellAmount} {swap.sellCurrency}
        </dd>
        <dt>
          <Small>Buy</Small>
        </dt>
        <dd>
          {swap.buyAmount} {swap.buyCurrency}
        </dd>
      </dl>
    </Rounded>
  );
};

const SwapSteps = ({ swap, ...rest }) => {
  const { flow } = swap;

  if (!flow) return null;

  const confirmed = flow.isMeSigned || flow.isParticipantSigned;
  const deposit =
    flow.btcScriptCreatingTransactionHash &&
    flow.ethSwapCreationTransactionHash;
  const withdraw =
    flow.btcSwapWithdrawTransactionHash && flow.ethSwapWithdrawTransactionHash;
  const finished = flow.isFinished;

  return (
    <Rounded {...rest}>
      <ul>
        <li>
          <SwapStep done={confirmed} name={"Confirmation"} />
        </li>
        <li>
          <SwapStep done={deposit} name={"Deposit"} />
        </li>
        <li>
          <SwapStep done={withdraw} name={"Withdraw"} />
        </li>
        <li>
          <SwapStep done={finished} name={"Done"} />
        </li>
      </ul>
    </Rounded>
  );
};

const SwapStep = ({ done, name }) => (
  <div className={classnames(styles.step, { [styles.done]: done })}>
    {done ? <IconCheckCircle /> : <IconCircle />}
    <span>{name}</span>
  </div>
);

const SwapLinks = ({ swap, ...rest }) => {
  const { flow } = swap;

  if (!flow) return null;

  return (
    <Rounded {...rest}>
      <ul>
        <li>
          <SwapLink
            ticker="BTC"
            name="BTC deposit TX"
            hash={flow.btcScriptCreatingTransactionHash}
          />
        </li>
        <li>
          <SwapLink
            ticker="ETH"
            name="ETH deposit TX"
            hash={flow.ethSwapCreationTransactionHash}
          />
        </li>
        <li>
          <SwapLink
            ticker="BTC"
            name="BTC withdraw TX"
            hash={flow.btcSwapWithdrawTransactionHash}
          />
        </li>
        <li>
          <SwapLink
            ticker="ETH"
            name="ETH withdraw TX"
            hash={flow.ethSwapWithdrawTransactionHash}
          />
        </li>
      </ul>
    </Rounded>
  );
};

const SwapLink = ({ hash, ticker, name }) => (
  <div className={styles.link}>
    <Small>{name}</Small>
    <div>
      {hash ? (
        <div>
          <a
            href={txUrl(ticker, hash)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {hash}
          </a>
        </div>
      ) : (
        "..."
      )}
    </div>
  </div>
);

export default Swap;
