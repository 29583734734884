import { STR } from "../actions/swap";

export const initial = {
  sell: { ticker: "ETH", name: "Ether" },
  buy: { ticker: "BTC", name: "Bitcoin" },
  ordersUpdated: 0,
  appReady: false,
};

function isSameCurrency(a, b) {
  return a.ticker === b.ticker;
}

export function reducer(state = initial, action) {
  switch (action.type) {
    case STR.SET_APP_READY:
      return { ...state, appReady: true };
    case STR.SET_APP_NOT_READY:
      return { ...state, appReady: false };
    case STR.SET_CURRENCY_BUY:
      return {
        ...state,
        buy: action.currency,
        sell: isSameCurrency(state.sell, action.currency)
          ? state.buy
          : state.sell,
      };
    case STR.SET_CURRENCY_SELL:
      return {
        ...state,
        buy: isSameCurrency(state.buy, action.currency)
          ? state.sell
          : state.buy,
        sell: action.currency,
      };
    case STR.SWITCH_CURRENCIES:
      return { ...state, sell: state.buy, buy: state.sell };
    case STR.UPDATE_ORDERS:
      return {
        ...state,
        ordersUpdated: Date.now(),
      };
    default:
      return state;
  }
}
