export const STR = {
  SET_CURRENCY_BUY: "SWAP_SET_CURRENCY_BUY",
  SET_CURRENCY_SELL: "SWAP_SET_CURRENCY_SELL",
  SWITCH_CURRENCIES: "SWAP_SWITCH_CURRENCIES",
  SET_APP_READY: "SWAP_SET_APP_READY",
  SET_APP_NOT_READY: "SWAP_SET_APP_NOT_READY",
  UPDATE_ORDERS: "SWAP_UPDATE_ORDERS",
  CREATE_ORDER: "SWAP_CREATE_ORDER",
  COPY_ORDER: "SWAP_COPY_ORDER",
  REMOVE_MY_ORDER: "SWAP_REMOVE_MY_ORDER",
  INITIATE_SWAP: "SWAP_INITIATE_SWAP",
  SEND_ORDER_REQUEST: "SWAP_SEND_ORDER_REQUEST",
  RECEIVE_ORDER_REQUEST: "SWAP_RECEIVE_ORDER_REQUEST",
  CREATE_SWAP: "SWAP_CREATE_SWAP",
  CREATE_SWAP_COMPLETE: "SWAP_CREATE_SWAP_COMPLETE",
  SWAP_FINISHED: "SWAP_SWAP_FINISHED",
};

export const setCurrencyBuy = (currency) => ({
  type: STR.SET_CURRENCY_BUY,
  currency,
});

export const setCurrencySell = (currency) => ({
  type: STR.SET_CURRENCY_SELL,
  currency,
});

export const switchCurrencies = () => ({
  type: STR.SWITCH_CURRENCIES,
});

export const setAppReady = () => ({
  type: STR.SET_APP_READY,
});

export const setAppNotReady = () => ({
  type: STR.SET_APP_NOT_READY,
});

export const updateOrders = () => ({
  type: STR.UPDATE_ORDERS,
});

export const createOrder = (data, callback) => ({
  type: STR.CREATE_ORDER,
  data,
  callback,
});

export const copyOrder = (orderId) => ({
  type: STR.COPY_ORDER,
  orderId,
});

export const removeMyOrder = (orderId) => ({
  type: STR.REMOVE_MY_ORDER,
  orderId,
});

export const initiateSwap = (orderId) => ({
  type: STR.INITIATE_SWAP,
  orderId,
});

export const sendOrderRequest = (order) => ({
  type: STR.SEND_ORDER_REQUEST,
  order,
});

export const receiveOrderRequest = (request) => ({
  type: STR.RECEIVE_ORDER_REQUEST,
  request,
});

export const createSwap = (order) => ({
  type: STR.CREATE_SWAP,
  order,
});

export const createSwapComplete = (order, swap) => ({
  type: STR.CREATE_SWAP_COMPLETE,
  order,
  swap,
});

export const swapFinished = (swap) => ({
  type: STR.SWAP_FINISHED,
  swap,
});
