import React from "react";
import classnames from "classnames";
import {
  AlertCircle as IconAlertCircle,
  PlayCircle as IconPlayCircle,
  CheckCircle as IconCheckCircle,
} from "react-feather";
import { format, differenceInMinutes } from "date-fns";

import { useWindowSize } from "../util/breakpoints";

import { Col } from "./Grid";
import { Title, Small } from "./Typography";
import { Rounded } from "./Boxes";

import buttonStyles from "./Button.module.css";

import styles from "./HistoryView.module.css";

const HistoryView = ({ swaps = [], swapActions }) => {
  const { isLarge, onMobile } = useWindowSize();

  const headers = isLarge
    ? ["TIME", "AMOUNT", "PRICE", "TOTAL", "ACTIONS"]
    : !onMobile
    ? ["TIME", "AMOUNT", "TOTAL", "ACTIONS"]
    : ["TIME", "ACTIONS"];

  return (
    <Col>
      <Title>History</Title>
      <Rounded bottomRight>
        <table className={styles.table}>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={`header-${header}`}>
                  <Small>{header}</Small>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {swaps.map((swap, i) => (
              <tr key={`order-${i}`}>
                <td>
                  <StatusIndicator swap={swap} />
                  {format(
                    new Date(swap.createUnixTimeStamp * 1000),
                    "PP kk:mm"
                  )}
                </td>
                {!onMobile && (
                  <td>
                    <Small style={{ marginRight: "0.3rem" }}>Buying</Small>
                    {swap.buyAmount}
                    <Small style={{ marginLeft: "0.3rem" }}>
                      {swap.buyCurrency}
                    </Small>
                  </td>
                )}
                {isLarge && (
                  <td>
                    <Small style={{ marginRight: "0.3rem" }}>at</Small>
                    {(swap.sellAmount / swap.buyAmount).toFixed(8)}
                    <Small style={{ marginLeft: "0.3rem" }}>
                      {swap.sellCurrency} / {swap.buyCurrency}
                    </Small>
                  </td>
                )}
                {!onMobile && (
                  <td>
                    <Small style={{ marginRight: "0.3rem" }}>for</Small>

                    {swap.sellAmount}
                    <Small style={{ marginLeft: "0.3rem" }}>
                      {swap.sellCurrency}
                    </Small>
                  </td>
                )}
                <td>
                  <button
                    className={classnames(
                      buttonStyles.button,
                      buttonStyles.primary
                    )}
                    onClick={() => swapActions.viewSwap(swap.id)}
                  >
                    View swap
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Rounded>
    </Col>
  );
};

const StatusIndicator = ({ swap }) => {
  const finished = swap.flow.isFinished;
  const stuck =
    !finished &&
    differenceInMinutes(new Date(), new Date(swap.createUnixTimeStamp * 1000)) >
      5;
  const processing = !finished && !stuck;

  return (
    <span
      className={classnames(styles.status, {
        [styles.finished]: finished,
        [styles.processing]: processing,
        [styles.stuck]: stuck,
      })}
    >
      {processing && <IconPlayCircle />}
      {finished && <IconCheckCircle />}
      {stuck && <IconAlertCircle />}
    </span>
  );
};
export default HistoryView;
