export default {
  network: "testnet",
  api: {
    btc: "https://test-insight.swaponline.io/insight-api",
    eth: "https://rinkeby.infura.io/v3/5ffc47f65c4042ce847ef66a3fa70d4c",
  },
  feeRates: {
    btc: "https://api.blockcypher.com/v1/btc/test3",
    eth: "",
  },
  explorer: {
    btc: "https://tbtc.bitaps.com",
    eth: "https://rinkeby.etherscan.io",
  },
  p2p: {
    starServer:
      "/dns4/wrtc-star.sprawl.equilibrium.co/tcp/443/wss/p2p-webrtc-star",
    roomName: "testnet.swap.sprawl",
  },
  swapContract: { eth: "0x82fD4CE0F382aaE8B8D69F81bc986bE135236590" },
};
