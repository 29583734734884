import { connect } from "react-redux";

import * as uiActions from "../redux/actions/ui";

import Header from "../components/Header";

const mapDispatchToProps = (dispatch) => ({
  toggleSideBar: (...args) => dispatch(uiActions.toggleSideBar(...args)),
});

export default connect(null, mapDispatchToProps)(Header);
