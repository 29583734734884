import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";

import { Col } from "./Grid";

import { useWindowSize } from "../util/breakpoints";

import styles from "./Sidebar.module.css";

const defaultColor = "#D1CFD3";
const highlightColor = "#AD0089";

const SideBar = (props) => {
  const { onMobile } = useWindowSize();
  const location = useLocation();

  const handleClose = () => {
    if (onMobile) props.close();
  };

  return (
    <div
      className={classnames(styles.wrapper, { [styles.open]: props.isOpen })}
    >
      <Col className={styles.sideBar}>
        {props.pages.map((page) => (
          <NavItem
            key={`sideBarLink-${page.path}`}
            page={page}
            location={location}
            onClick={handleClose}
          />
        ))}
      </Col>
      <div className={styles.overlay} onClick={handleClose} />
    </div>
  );
};

const NavItem = ({ page, location, ...rest }) => {
  const Icon = page.icon;
  return (
    <NavLink
      exact={page.exact}
      to={page.path}
      activeClassName={styles.active}
      {...rest}
    >
      <Icon
        color={location.pathname === page.path ? highlightColor : defaultColor}
      />
      {page.name}
    </NavLink>
  );
};

export default SideBar;
