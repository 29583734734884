import { createSelector } from "reselect";

import SwapApp from "../../vendor/swap.core/swap.app";

import SwapCache from "../../util/swapCache";
import { objectSort } from "../../util/sort";

export const getOrderById = (id) =>
  SwapApp.shared().services.orders.getByKey(id);

export const getSwapById = SwapCache.getOrCreate;

export const activeBuy = (state) => state.swap.buy;
export const activeSell = (state) => state.swap.sell;
export const appReady = (state) => state.swap.appReady;
export const ordersUpdated = (state) => state.swap.ordersUpdated;

export const allOrders = createSelector(appReady, (appReady) => {
  if (!appReady) return [];
  return SwapApp.shared().services.orders.items;
});

export const relevantOrders = createSelector(
  activeBuy,
  activeSell,
  allOrders,
  ordersUpdated,
  (activeBuy, activeSell, allOrders) =>
    allOrders
      .filter(
        (order) =>
          !order.isHidden &&
          order.buyCurrency === activeSell.ticker &&
          order.sellCurrency === activeBuy.ticker
      )
      .map((order) => ({
        ...order,
        price: order.sellAmount / order.buyAmount,
      }))
      .sort(objectSort("price"))
      .reverse()
);

// TODO: Make sure this is the way we want it to be
export const marketRate = createSelector(relevantOrders, (relevantOrders) =>
  relevantOrders && relevantOrders.length ? relevantOrders[0].price : 0
);
