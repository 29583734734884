import debug from "debug";
import Libp2p from "libp2p";
import WS from "libp2p-websockets";
import WebRTCStar from "libp2p-webrtc-star";
import SECIO from "libp2p-secio";
import Mplex from "libp2p-mplex";
import KadDHT from "libp2p-kad-dht";
import GossipSub from "libp2p-gossipsub";
import PeerInfo from "peer-info";
import PeerId from "peer-id";

import config from "../../config";

const log = debug("sprawl:core.p2p");

const defaultOptions = {
  peerId: null,
  starServers: [config.p2p.starServer],
};

const create = async (options = {}) => {
  options = { ...defaultOptions, ...options };

  let peerInfo;
  let peerId;
  try {
    peerId = await PeerId.createFromJSON(options.peerId);
  } catch (e) {
    console.info("Unable to parse peerInfo from storage, creating new");
    peerId = await PeerId.create();
  }

  peerInfo = new PeerInfo(peerId);

  const node = await Libp2p.create({
    peerInfo,
    modules: {
      transport: [WS, WebRTCStar],
      connEncryption: [SECIO],
      streamMuxer: [Mplex],
      peerDiscovery: [],
      dht: KadDHT,
      pubsub: GossipSub,
    },
    config: {
      peerDiscovery: {
        autoDial: true,
        webRTCStar: {
          enabled: true,
        },
        websocketStar: {
          enabled: true,
        },
      },
      pubsub: {
        enabled: true,
        emitSelf: true,
      },
    },
  });

  // Add signaling servers
  options.starServers.forEach((addr) => {
    node.peerInfo.multiaddrs.add(addr);
  });

  await node.start();

  node.on("peer:connect", (peer) => log("connect", peer));
  node.on("peer:discovery", (peer) => log("discovery", peer));
  node.on("peer:disconnect", (peer) => log("disconnect", peer));

  return node;
};

export default create;
