import React from "react";
import classnames from "classnames";

import { useWindowSize } from "../util/breakpoints";

import styles from "./Grid.module.css";

const GridComponent = ({
  type,
  className,
  hideWhenSmall,
  hideWhenMedium,
  hideWhenLarge,
  colSpan,
  rowSpan,
  style,
  ...rest
}) => {
  const { isSmall, isMedium, isLarge } = useWindowSize();

  if (hideWhenSmall && isSmall) return null;
  if (hideWhenMedium && isMedium) return null;
  if (hideWhenLarge && isLarge) return null;

  const gridColumn = isLarge && colSpan ? `span ${colSpan}` : null;
  const gridRow = isLarge && rowSpan ? `span ${rowSpan}` : null;

  return (
    <div
      className={classnames(styles[type], className)}
      style={{ ...style, gridColumn, gridRow }}
      {...rest}
    />
  );
};

export const Main = (props) => GridComponent({ type: "main", ...props });
export const Grid = (props) => GridComponent({ type: "grid", ...props });
export const Col = (props) => GridComponent({ type: "col", ...props });
export const Row = (props) => GridComponent({ type: "row", ...props });
