import { connect } from "react-redux";

import * as userActions from "../redux/actions/user";
import * as userSelectors from "../redux/selectors/user";

import SettingsView from "../components/SettingsView";

const mapStateToProps = (state) => ({
  hiddenCurrencies: userSelectors.hiddenCurrencies(state),
  visibleCurrencies: userSelectors.visibleCurrencies(state),
});

const mapDispatchToProps = (dispatch) => ({
  hideCurrency: (...args) => dispatch(userActions.hideCurrency(...args)),
  unhideCurrency: (...args) => dispatch(userActions.unhideCurrency(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
