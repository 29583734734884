import React from "react";

import { Col, Row } from "./Grid";
import { Large, Title } from "./Typography";
import { TextInput, NumberInput, Button } from "./Input";

import styles from "./AdminView.module.css";

const AdminView = (props) => {
  const handleCreateOrder = (orderData) => {
    if (props.swapAppReady) props.createOrder(orderData);
  };

  const handleRequestFunds = ({ currency, amount }) => {
    if (props.swapAppReady) props.requestFunds(currency, amount);
  };

  return (
    <Col>
      <Title>Admin</Title>

      <OfferForm enabled={props.swapAppReady} onSubmit={handleCreateOrder} />

      <RequestFundsForm
        enabled={props.swapAppReady}
        onSubmit={handleRequestFunds}
      />
    </Col>
  );
};

const OfferForm = ({ enabled, onSubmit }) => {
  const [formState, setFormState] = React.useState({
    buyCurrency: "ETH",
    buyAmount: "1",
    sellCurrency: "BTC",
    sellAmount: "1",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState);
  };

  const handleInputChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className={styles.formRow}>
        <Col>
          <Large>Create order</Large>
        </Col>
      </Row>
      <Row className={styles.formRow}>
        <Col>
          <TextInput
            name="buyCurrency"
            label={"BuyCurrency"}
            onChange={handleInputChange}
            value={formState.buyCurrency}
          />
        </Col>
        <Col>
          <NumberInput
            name="buyAmount"
            label={"BuyAmount"}
            onChange={handleInputChange}
            value={formState.buyAmount}
          />
        </Col>
      </Row>
      <Row className={styles.formRow}>
        <Col>
          <TextInput
            name="sellCurrency"
            label={"SellCurrency"}
            onChange={handleInputChange}
            value={formState.sellCurrency}
          />
        </Col>
        <Col>
          <NumberInput
            name="sellAmount"
            label={"SellAmount"}
            onChange={handleInputChange}
            value={formState.sellAmount}
          />
        </Col>
      </Row>
      <Row className={styles.formRow}>
        <Col>
          <Button disabled={!enabled}>
            <Large>Create order</Large>
          </Button>
        </Col>
      </Row>
    </form>
  );
};

const RequestFundsForm = ({ enabled, onSubmit }) => {
  const [formState, setFormState] = React.useState({
    currency: "ETH",
    amount: "1",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState);
  };

  const handleInputChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className={styles.formRow}>
        <Col>
          <Large>Request funds</Large>
        </Col>
      </Row>
      <Row className={styles.formRow}>
        <Col>
          <TextInput
            name="currency"
            label={"Currency"}
            onChange={handleInputChange}
            value={formState.currency}
          />
        </Col>
        <Col>
          <NumberInput
            name="amount"
            label={"Amount"}
            onChange={handleInputChange}
            value={formState.amount}
          />
        </Col>
      </Row>
      <Row className={styles.formRow}>
        <Col>
          <Button disabled={!enabled}>
            <Large>Request</Large>
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default AdminView;
