import { compose, createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import persistState from "redux-localstorage";
import createSagaMiddleware from "redux-saga";
import deepmerge from "deepmerge";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import { createRootReducer, initialState } from "./reducers";
import rootSaga from "./sagas";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();

// Define a custom merge function since redux-localstorage's only extends
// states. We need deep merge.
const customStateMerge = (initialState, persistedState) =>
  deepmerge(initialState, persistedState, {
    // Define arrayMerge to override initial states arrays with persisted states
    arrayMerge: (_, persistedArray) => persistedArray,
  });

const _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history), // Reducer
  initialState, // State
  _compose(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      loggerMiddleware
    ),
    persistState(["user", "swapHistory"], {
      merge: customStateMerge,
    })
  )
);

sagaMiddleware.run(rootSaga);

export default store;
