import React from "react";

import { ReactComponent as BitcoinLogo } from "../assets/currencies/bitcoin.svg";
import { ReactComponent as EthereumLogo } from "../assets/currencies/ether.svg";
import { ReactComponent as TetherLogo } from "../assets/currencies/tether.svg";

const mapNameToLogo = {
  btc: BitcoinLogo,
  eth: EthereumLogo,
  usdt: TetherLogo,
};

const CurrencyLogo = ({ name = "btc", className, ...rest }) => {
  const Logo = mapNameToLogo[name.toLowerCase()];
  return (
    <Logo
      className={className ? className : "currencyLogo"}
      alt={name}
      {...rest}
    />
  );
};

export default CurrencyLogo;
