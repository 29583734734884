import SwapApp from "../vendor/swap.core/swap.app";
import Swap from "../vendor/swap.core/swap.swap";

export function SwapCache(app = null) {
  this.app = app;

  this.createSwap = (order) => this.getOrCreate(order.id, order, true);

  this.getOrCreate = (id, order, create = false) => {
    let swap = Swap.read(this.app || SwapApp.shared(), { id });
    if (Object.keys(swap).length === 0 && swap.constructor === Object)
      if (create) swap = new Swap(id, this.app || SwapApp.shared(), order);
      else return null;
    return swap;
  };
}

const swapCache = new SwapCache();

export default swapCache;
