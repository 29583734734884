import { createSelector } from "reselect";

export const availableCurrencies = (state) => state.user.availableCurrencies;

export const hiddenCurrencyTickers = (state) =>
  state.user.hiddenCurrencyTickers;

export const visibleCurrencies = createSelector(
  availableCurrencies,
  hiddenCurrencyTickers,
  (availableCurrencies, hiddenCurrencyTickers) =>
    availableCurrencies.filter(
      (c) => hiddenCurrencyTickers.indexOf(c.ticker) === -1
    )
);

export const hiddenCurrencies = createSelector(
  availableCurrencies,
  hiddenCurrencyTickers,
  (availableCurrencies, hiddenCurrencyTickers) =>
    availableCurrencies.filter(
      (c) => hiddenCurrencyTickers.indexOf(c.ticker) !== -1
    )
);
