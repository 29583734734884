import React from "react";

const smallBreakPoint = 1000;
const mediumBreakPoint = 1300;

export const isSmall = (width) => width <= smallBreakPoint;
export const isMedium = (width) => width <= mediumBreakPoint;
export const isLarge = (width) => width > mediumBreakPoint;
export const onMobile = isSmall;

export function useWindowSize() {
  const isClient = typeof window === "object";

  const getSize = React.useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  );

  const [{ width }, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]);

  return {
    isSmall: isSmall(width),
    isMedium: isMedium(width),
    isLarge: isLarge(width),
    onMobile: onMobile(width),
  };
}
