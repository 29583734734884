import debug from "debug";
import { Events, ServiceInterface } from "../swap.app";

class SwapRoom extends ServiceInterface {
  static get name() {
    return "room";
  }

  constructor(config = {}) {
    super();

    if (typeof config !== "object") {
      throw new Error('SwapRoomService: "config" of type object required');
    }

    this._serviceName = "room";
    this._config = config;
    this.events = new Events();
    this.peer = null;
    this.connection = null;
    this.roomName = null;
  }

  initService() {
    if (!this.app.env.p2pLib) {
      throw new Error("SwapRoomService: p2pLib required");
    }
    if (!this.app.env.IpfsRoom) {
      throw new Error("SwapRoomService: IpfsRoom required");
    }

    this.app.env.p2pLib
      .create({ peerId: this.app.env.peerId })
      .then(this._init.bind(this))
      .catch((error) => {
        console.error(error);
      });
  }

  _init(p2pNode) {
    this.p2pNode = p2pNode;
    this.peer = p2pNode.peerInfo.id.toB58String();

    const defaultRoomName = this.app.isMainNet()
      ? "swap.sprawl"
      : "testnet.swap.sprawl";

    this.roomName = this._config.roomName || defaultRoomName;

    debug("swap.core:room")(`Using room: ${this.roomName}`);

    this.connection = new this.app.env.IpfsRoom(p2pNode, this.roomName);

    this.connection.on("peer joined", this._handleUserOnline);
    this.connection.on("peer left", this._handleUserOffline);
    this.connection.on("message", this._handleNewMessage);

    this.events.dispatch("ready");
  }

  _handleUserOnline = (peer) => {
    if (peer !== this.peer) {
      this.events.dispatch("user online", peer);
    }
  };

  _handleUserOffline = (peer) => {
    if (peer !== this.peer) {
      this.events.dispatch("user offline", peer);
    }
  };

  _handleNewMessage = (message) => {
    const { from, data: rawData } = message;
    debug("swap.verbose:room")("message from", from);

    if (from === this.peer) {
      return;
    }

    let parsedData;

    try {
      parsedData = JSON.parse(rawData.toString());
    } catch (err) {
      console.error("parse message data err:", err);
    }

    const { fromAddress, data, sign, event, action } = parsedData;

    if (!data) {
      return;
    }

    debug("swap.verbose:room")("parsedData", parsedData);

    const recover = this._recoverMessage(data, sign);

    if (recover !== fromAddress) {
      console.error(
        `Wrong message sign! Message from: ${fromAddress}, recover: ${recover}`
      );
      return;
    }

    if (action === "active") {
      this.acknowledgeReceipt(parsedData);
    }

    this.events.dispatch(event, {
      fromPeer: from,
      ...data,
    });
  };

  on(eventName, handler) {
    this.events.subscribe(eventName, handler);
    return this;
  }

  off(eventName, handler) {
    this.events.unsubscribe(eventName, handler);
    return this;
  }

  once(eventName, handler) {
    this.events.once(eventName, handler);
    return this;
  }

  subscribe(eventName, handler) {
    this.events.subscribe(eventName, handler);
    return this;
  }

  unsubscribe(eventName, handler) {
    this.events.unsubscribe(eventName, handler);
    return this;
  }

  _recoverMessage(message, sign) {
    const hash = this.app.env.web3.utils.soliditySha3(JSON.stringify(message));
    const recover = this.app.env.web3.eth.accounts.recover(
      hash,
      sign.signature
    );

    return recover;
  }

  _signMessage(message) {
    const hash = this.app.env.web3.utils.soliditySha3(JSON.stringify(message));
    const sign = this.app.env.web3.eth.accounts.sign(
      hash,
      this.app.services.auth.accounts.eth.privateKey
    );

    return sign;
  }

  checkReceiving(message, callback) {
    let address = message.fromAddress;

    const waitReceipt = (data) => {
      if (!data.action || data.action !== "confirmation") {
        return;
      }

      if (JSON.stringify(message.data) === JSON.stringify(data.message)) {
        this.unsubscribe(address, waitReceipt);

        if (this.CheckReceiptsT[message.peer]) {
          clearTimeout(this.CheckReceiptsT[message.peer]);
        }

        callback(true);
      }
    };

    this.subscribe(address, waitReceipt);

    if (!this.CheckReceiptsT) {
      this.CheckReceiptsT = {};
    }

    this.CheckReceiptsT[message.peer] = setTimeout(() => {
      this.unsubscribe(address, waitReceipt);

      callback(false);
    }, 15000);
  }

  sendConfirmation(peer, message, callback = false, repeat = 9) {
    if (!this.connection) {
      setTimeout(() => {
        this.sendConfirmation(peer, message, callback, repeat);
      }, 1000);
      return;
    }

    if (message.action === "confirmation" && peer !== this.peer) {
      return;
    }

    message = this.sendMessagePeer(peer, message);

    this.checkReceiving(message, (delivered) => {
      if (!delivered && repeat > 0) {
        repeat--;
        setTimeout(() => {
          this.sendConfirmation(peer, message, callback, repeat);
        }, 1000);
        return;
      }

      if (callback) callback(delivered);
    });
  }

  acknowledgeReceipt(message) {
    if (
      !message.peer ||
      !message.action ||
      message.action === "confirmation" ||
      message.action === "active"
    ) {
      return;
    }

    const { fromAddress, data } = message;

    this.sendMessagePeer(fromAddress, {
      action: "confirmation",
      data,
    });
  }

  sendMessagePeer(peer, message) {
    if (!this.connection) {
      if (message.action !== "active") {
        setTimeout(() => {
          this.sendMessagePeer(peer, message);
        }, 999);
      }
      return;
    }

    debug("swap.verbose:room")("sent message to peer", peer);
    // debug('swap.verbose:room')('message', message)

    const { data, event } = message;
    const sign = this._signMessage(data);

    this.connection.sendTo(
      peer,
      JSON.stringify({
        fromAddress: this.app.services.auth.accounts.eth.address,
        data,
        event,
        sign,
      })
    );

    return message;
  }

  sendMessageRoom(message) {
    const { data, event } = message;
    const sign = this._signMessage(data);

    this.connection.broadcast(
      JSON.stringify({
        fromAddress: this.app.services.auth.accounts.eth.address,
        data,
        event,
        sign,
      })
    );
  }
}

export default SwapRoom;
