import { STR } from "../actions/p2p";

export const initial = {
  online: false,
  onlineUsers: 0,
};

export function reducer(state = initial, action) {
  switch (action.type) {
    case STR.READY:
      return { ...state, online: true };
    case STR.STOP:
      return { ...state, online: false };
    case STR.USER_JOINED:
      return { ...state, onlineUsers: state.onlineUsers + 1 };
    case STR.USER_LEFT:
      return { ...state, onlineUsers: Math.max(0, state.onlineUsers - 1) };
    default:
      return state;
  }
}
