import React from "react";
// import { Bell as IconBell } from "react-feather";

import { Row } from "./Grid";

import { useWindowSize } from "../util/breakpoints";

import { ReactComponent as SprawlBall } from "../assets/images/sprawl-ball.svg";

import styles from "./Header.module.css";

const Header = (props) => {
  const { onMobile } = useWindowSize();

  const handleToggleSideBar = () => {
    if (onMobile) props.toggleSideBar();
  };

  return (
    <Row className={styles.header}>
      <span className={styles.sprawlLogo} onClick={handleToggleSideBar}>
        <SprawlBall alt="sprawl-logo" />
      </span>
      {/* <nav className={styles.links}>
        <button href="#">Node</button>
        <button href="#">Wallet</button>
        <button href="#">
          <IconBell />
        </button>
      </nav> */}
    </Row>
  );
};

export default Header;
