import { STR } from "../actions/user";
import { STR as P2P_STR } from "../actions/p2p";

import allCurrencies from "../../data/currencies.json";

export const initial = {
  hiddenCurrencyTickers: [],
  availableCurrencies: allCurrencies,
  peerId: null,
};

export function reducer(state = initial, action) {
  switch (action.type) {
    case STR.HIDE_CURRENCY:
      return {
        ...state,
        hiddenCurrencyTickers: Array.from(
          new Set([...state.hiddenCurrencyTickers, action.currency.ticker])
        ),
      };
    case STR.UNHIDE_CURRENCY:
      return {
        ...state,
        hiddenCurrencyTickers: state.hiddenCurrencyTickers.filter(
          (t) => t !== action.currency.ticker
        ),
      };
    case P2P_STR.READY:
      return { ...state, peerId: action.peerInfo.id };
    default:
      return state;
  }
}
