import config from "../config";

export default function (currencyTicker, address) {
  if (typeof currencyTicker !== "string" || typeof address !== "string")
    return null;

  switch (currencyTicker.toLowerCase()) {
    case "eth":
      return `${config.explorer.eth}/address/${address}`;
    case "btc":
      return `${config.explorer.btc}/${address}`;
    default:
      return null;
  }
}

export function txUrl(currencyTicker, hash) {
  if (typeof currencyTicker !== "string" || typeof hash !== "string")
    return null;

  switch (currencyTicker.toLowerCase()) {
    case "eth":
      return `${config.explorer.eth}/tx/${hash}`;
    case "btc":
      return `${config.explorer.btc}/${hash}`;
    default:
      return null;
  }
}
