import {
  call,
  put,
  fork,
  delay,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";

import SwapApp from "../../vendor/swap.core/swap.app";

import * as walletActions from "../actions/wallet";
import * as swapActions from "../actions/swap";

import requestFundsApi from "../../util/requestFunds";

function* updateBalance(action) {
  const currencyTicker = action.currencyTicker;
  const publicData = SwapApp.shared().services.auth.getPublicData()[
    currencyTicker.toLowerCase()
  ];
  const swapInstance = SwapApp.shared().swaps[currencyTicker];
  try {
    const balance = yield call(swapInstance.fetchBalance, publicData.address);
    if (!isNaN(balance))
      yield put(walletActions.updateBalanceSuccess(currencyTicker, balance));
  } catch (e) {
    console.error(e);
  }
}

function updateBTCBalance() {
  return updateBalance({ currencyTicker: "BTC" });
}

function updateETHBalance() {
  return updateBalance({ currencyTicker: "ETH" });
}

function* updateAllBalances() {
  yield fork(updateBTCBalance);
  yield fork(updateETHBalance);
}

function* fetchBalancesPeriodically() {
  while (true) {
    yield fork(updateAllBalances);
    yield delay(60 * 1000);
  }
}

function* requestFunds(action) {
  const currencyTicker = action.currencyTicker;
  const publicData = SwapApp.shared().services.auth.getPublicData()[
    currencyTicker.toLowerCase()
  ];
  try {
    yield call(requestFundsApi, {
      address: publicData.address,
      currencyTicker,
      amount: action.amount,
    });
    yield put(walletActions.requestFundsSuccess(currencyTicker));
  } catch (e) {
    console.error(e);
  }
}

export default function* wallet() {
  yield takeEvery(walletActions.STR.UPDATE_BALANCE, updateBalance);
  yield takeLatest(walletActions.STR.UPDATE_BTC_BALANCE, updateBTCBalance);
  yield takeLatest(walletActions.STR.UPDATE_ETH_BALANCE, updateETHBalance);
  yield takeLeading(swapActions.STR.SET_APP_READY, fetchBalancesPeriodically);
  yield takeLatest(walletActions.STR.REQUEST_FUNDS, requestFunds);
  yield takeEvery(walletActions.STR.REQUEST_FUNDS_SUCCESS, updateBalance);
  yield takeEvery(swapActions.STR.SWAP_FINISHED, updateAllBalances);
}
