import { connect } from "react-redux";

import * as swapActions from "../redux/actions/swap";

import * as swapSelectors from "../redux/selectors/swap";

import TradeView from "../components/TradeView";

const mapStateToProps = (state) => ({
  activeBuy: swapSelectors.activeBuy(state),
  activeSell: swapSelectors.activeSell(state),
  balances: state.wallet.balance,
  orders: swapSelectors.relevantOrders(state),
  swapAppReady: state.swap.appReady,
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: (...args) => dispatch(swapActions.createOrder(...args)),
  orderActions: {
    initiateSwap: (...args) => dispatch(swapActions.initiateSwap(...args)),
    removeMyOrder: (...args) => dispatch(swapActions.removeMyOrder(...args)),
    copyOrder: (...args) => dispatch(swapActions.copyOrder(...args)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TradeView);
